export const ADMIN_PROFILE_ID = 1734

export const COPYRIGHT = `${new Date().getFullYear()} Skainet Ltd`

export const DEFAULT_LOCALE = 'en'

export const FOREVER_TIME = new Date('2030-01-01').getTime()

export const INFO_EMAIL = 'info@romancesgroup.com'

export const LOCALES = [
	'ar',
	'da',
	'de',
	'en',
	'es',
	'fi',
	'fr',
	'hi',
	'id',
	'it',
	'ja',
	'ko',
	'ms',
	'nl',
	'no',
	'pt',
	'ru',
	'sv',
	'th',
	'tl',
	'tr',
	'vi',
	'zh',
]

export const MONTH_SHORT_NAMES = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]

export const PASSWORD_MIN_LENGTH = 5
export const PASSWORD_MAX_LENGTH = 20

// SF210326 Must be bigger than watermark's width (currently 410px)
export const PHOTO_MIN_SIZE = 450

export type Product = {
	id: 0 | 1 | 2
	amountUSD: number
	amountCNY: number
	amountString: string
	googleProductId: string
	months: number
}

export const PRODUCTS: Product[] = [
	{
		id: 0,
		amountUSD: 2499,
		amountCNY: 16499,
		amountString: '24.99',
		googleProductId: 'membership_2',
		months: 2,
	},
	{
		id: 1,
		amountUSD: 4586,
		amountCNY: 29900,
		amountString: '45.86',
		googleProductId: 'membership_6',
		months: 6,
	},
	{
		id: 2,
		amountUSD: 7597,
		amountCNY: 49900,
		amountString: '75.97',
		googleProductId: 'membership_12',
		months: 12,
	},
]

// PWA primary color
export const THEME_COLOR = '#A9ADC1'

export const USERNAME_MIN_LENGTH = 3
export const USERNAME_MAX_LENGTH = 20
